// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import CircleAnimation from './components/animations/circleAnimation';
import Navbar from './components/navbar/Navbar';
import ProductSection from './components/products/ProductSection';
import ProductCard from './components/products/ProductCard';
import ProductDetails from './components/products/ProductDetails';
import Main from './components/Main';
import Development from './components/Development';


import './App.css';

function App() {
  return (
    <Router>
      <div className="App w-[100%] h-full bg-mainBg">

        <div className="container w-screen h-full">
          <Routes>
            <Route path="/*" element={<Development />} />
            <Route path="/development" element={<Development />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;


//TODO
//NAVBAR HEIGHT TO REDUX STATE FOR DYNAMIC ELEMENT PLACEMENT
//